import classNames from 'classnames';
import { FunctionComponent } from 'react';

import SearchIcon from 'src/icons/search.svg';

import styles from './SearchButtonLink.module.scss';

interface SearchButtonLinkProps {
  href: string;
  text: string;
  styleVariant?: 'primary' | 'secondary';
}

export const SearchButtonLink: FunctionComponent<SearchButtonLinkProps> = ({
  href,
  styleVariant = 'primary',
  text,
}) => {
  const isSecondary = styleVariant === 'secondary';

  return (
    <a aria-label={text} className={classNames(styles.searchLink, { [styles.linkSecondary]: isSecondary })} href={href}>
      <SearchIcon className={classNames(styles.searchIcon, { [styles.iconSecondary]: isSecondary })} role="img" />
    </a>
  );
};
