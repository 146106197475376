import { Component } from 'react';

import * as KatalMetrics from '@amzn/katal-metrics';

import initialMetricsPublisher from 'src/metrics';

enum INITIALIZATION_STATUS {
  Failure = 0,
  Success = 1,
}

interface PropsType {
  children: JSX.Element;
  errorMetricSuffix: string;
}

/**
 * Simple class to manage the application initialization metric.
 *
 * Note this is meant as a demonstration of how to use the initialization metric, and
 * is almost certainly inadequate for a more complex application.
 *
 * It waits for itself to be mounted (if it is the outermost component that means all of
 * its children are rendered and mounted by React, though are not necessarily fully rendered
 * in the browser), then waits for the translations to be ready.
 *
 * In your actual application, you are likely not fully initialized until you have completed
 * your initial REST calls.  You will need to either integrate that logic here, or else move
 * the initialization metric elsewhere.
 *
 * For applications using a state manager (e.g. Redux) it may make more sense to determine if
 * your application is initialized from that layer instead.
 */
export class AppInitWrapper extends Component<PropsType, {}> {
  private initializationMetric = new KatalMetrics.Metric.Initialization().withMonitor();

  private initializationMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForInitialization();

  private errorBoundaryMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('ErrorBoundary');

  private isInitialized = false;

  componentDidMount() {
    this.logInitializationMetric(INITIALIZATION_STATUS.Success);
  }

  componentDidCatch(error: Error) {
    const { errorMetricSuffix } = this.props;
    this.errorBoundaryMetricsPublisher.publishCounterMonitor(`ErrorBoundary.Encounter.${errorMetricSuffix}`, 1);
    // In your application you may want to update the UI; here we will re-throw and fail the whole app, after logging
    throw error;
  }

  // Helper method to publish the initialization metric
  private logInitializationMetric(status: INITIALIZATION_STATUS) {
    if (!this.isInitialized && this.initializationMetric && this.initializationMetricsPublisher) {
      this.isInitialized = true;
      this.initializationMetric.setFailure(status !== INITIALIZATION_STATUS.Success);
      this.initializationMetricsPublisher.publish(this.initializationMetric);
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}
