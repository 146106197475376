import { FunctionComponent } from 'react';
import arbManifest from 'translations/arbManifest';

import { MbmProvider } from '@amzn/react-arb-tools';

import useRum from 'src/components/common/UseRum';
import { Header } from 'src/components/header/Header';
import { getMbmOptions } from 'src/helpers';

const mbmOptions = getMbmOptions(arbManifest);

export const HeaderApp: FunctionComponent = () => {
  useRum('prod');
  return (
    <MbmProvider {...mbmOptions}>
      <Header />
    </MbmProvider>
  );
};
