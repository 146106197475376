import { FunctionComponent } from 'react';

import HamburgerIcon from 'src/icons/hamburger.svg';

import styles from './HamburgerButton.module.scss';

interface HamburgerButtonProps {
  ariaLabel: string;
  onClick: () => void;
}

export const HamburgerButton: FunctionComponent<HamburgerButtonProps> = ({ ariaLabel, onClick }) => (
  <button className={styles.hamburgerButton} type="button" aria-label={ariaLabel} onClick={onClick}>
    <HamburgerIcon className={styles.hamburgerIcon} role="img" />
  </button>
);
