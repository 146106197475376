import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppInitWrapper } from 'src/components/AppInitWrapper';

import { FooterApp } from './components/FooterApp';
import { HeaderApp } from './components/HeaderApp';

import './logger';
import './index.scss';

const headerElement = document.getElementById('ash-trim-header');
if (!headerElement) {
  throw new Error('Could not find nav root: ash-trim-header');
}
const footerElement = document.getElementById('ash-trim-footer');
if (!footerElement) {
  throw new Error('Could not find footer root: ash-trim-footer');
}

const headerRoot = createRoot(headerElement);
const footerRoot = createRoot(footerElement);

headerRoot.render(
  <React.StrictMode>
    <AppInitWrapper errorMetricSuffix="HeaderApp">
      <HeaderApp />
    </AppInitWrapper>
  </React.StrictMode>
);

footerRoot.render(
  <React.StrictMode>
    <AppInitWrapper errorMetricSuffix="FooterApp">
      <FooterApp />
    </AppInitWrapper>
  </React.StrictMode>
);
