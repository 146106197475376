import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';

import { ASX_USER_LOCALE } from 'src/constants';
import useDeviceSize from 'src/hooks/useDeviceSize';
import CheckIcon from 'src/icons/check.svg';
import ChevronDownIcon from 'src/icons/chevron-down.svg';

import styles from './LocalePicker.module.scss';

const DEFAULT_LOCALE = 'en-US';
const MONS_LOCALE_QUERY_PARAM = '?mons_sel_locale=';

// TODO: Add/Review full list of P0 Locales
const LABELS_BY_LOCALE: Record<string, string> = {
  'en-US': 'English (US)',
  'es-ES': 'Español (ES)',
  'es-MX': 'Español (MX)',
  'fr-FR': 'Français (FR)',
  'fr-CA': 'Français (CA)',
  'fr-BE': 'Français (BE)',
  'it-IT': 'Italiano (IT)',
  'de-DE': 'Deutsch (DE)',
  'ja-JP': '日本語 (日本)',
  'pt-BR': 'Português (BR)',
  'pt-PT': 'Português (PT)',
  'zh-CN': '普通话 (中国)',
};

interface LocaleOptionProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

/**
 * Component for individual locale option row in dropdown.
 */
const LocaleOption: FunctionComponent<LocaleOptionProps> = ({ label, isSelected, onClick }) => (
  <option className={styles.dropdownOption} onClick={onClick} onKeyDown={onClick}>
    {label}
    {isSelected && (
      <span className={styles.checkContainer}>
        <CheckIcon className={styles.checkIcon} />
      </span>
    )}
  </option>
);

type LocalePickerVariant = 'primary' | 'secondary';
interface LocalePickerProps {
  variant: LocalePickerVariant;
}

/**
 * Component for locale picker button and dropdown.
 */
export const LocalePicker: FunctionComponent<LocalePickerProps> = ({ variant = 'primary' }) => {
  const { isSmallDesktop } = useDeviceSize();
  const [isExpanded, setIsExpanded] = useState(false);
  const [currLocale, setCurrLocale] = useState(localStorage.getItem(ASX_USER_LOCALE) ?? DEFAULT_LOCALE);
  const isSecondary = variant === 'secondary';

  /**
   * On locale change, set localStorage value on browser and redirect with Mons Selection API
   * https://w.amazon.com/bin/view/Mons/Selections/#HMonsLanguageAPI
   */
  const handleChange = (localeCode: string) => {
    setCurrLocale(localeCode);
    localStorage.setItem(ASX_USER_LOCALE, localeCode);
    // Convert locale string to Mons format and invoke redirect URL to set cookie on future requests.
    const monsLocale = localeCode.replace('-', '_');
    window.location.assign(`${window.location.pathname}${MONS_LOCALE_QUERY_PARAM}${monsLocale}`);
  };

  const desktopDropdown = (
    <div
      className={classNames(styles.dropdown, styles.show)}
      onMouseOver={() => {
        setIsExpanded(true);
      }}
      onFocus={() => {
        setIsExpanded(true);
      }}
      onMouseOut={() => {
        setIsExpanded(false);
      }}
      onBlur={() => {
        setIsExpanded(false);
      }}
    >
      {Object.entries(LABELS_BY_LOCALE).map(([localeCode, label]) => (
        <LocaleOption
          key={localeCode}
          label={label}
          isSelected={currLocale === localeCode}
          onClick={() => handleChange(localeCode)}
        />
      ))}
    </div>
  );

  const mobileDropdown = (
    <div className={styles.mobileSelectWrapper}>
      <select
        aria-label="locale-picker"
        className={classNames(styles.mobileSelect)}
        data-testid="mobile-locale-picker"
        onChange={(event) => handleChange(event.target.value)}
        value={currLocale}
      >
        {Object.entries(LABELS_BY_LOCALE).map(([localeCode, label]) => (
          <option key={localeCode} label={label} value={localeCode} onClick={() => handleChange(localeCode)} />
        ))}
      </select>
    </div>
  );

  return isSmallDesktop ? (
    <div>
      {/* Locale Picker Button */}
      <div
        role="menu"
        tabIndex={0}
        aria-label="locale-picker"
        className={classNames(styles.localePicker, {
          [styles.secondaryLocalePicker]: isSecondary,
        })}
        onClick={() => {
          setIsExpanded(true);
        }}
        onKeyDown={() => {
          setIsExpanded(true);
        }}
      >
        <span className={classNames(styles.localeLabel, { [styles.secondaryLabel]: isSecondary })}>
          {LABELS_BY_LOCALE[currLocale]}
        </span>
        <span>
          <ChevronDownIcon className={classNames(styles.localePickerIcon, { [styles.secondaryIcon]: isSecondary })} />
        </span>
      </div>

      {/* Locale Picker Dropdown */}
      {isExpanded && desktopDropdown}
    </div>
  ) : (
    mobileDropdown
  );
};
