import { FunctionComponent } from 'react';

import styles from './CopyRight.module.scss';

interface CopyRightProps {
  text: string;
}

export const CopyRight: FunctionComponent<CopyRightProps> = ({ text }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.copyRightWrapper}>
      <span>{`© 1996-${currentYear} `}</span>
      <span className={styles.copyRightText}>{text}</span>
    </div>
  );
};
