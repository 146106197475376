import { useEffect, useState } from 'react';

import { FOOTER_NAV_ITEMS_LOCAL_STORAGE_KEY, FOOTER_NAV_ITEMS_UPDATED_EVENT_NAME } from 'src/constants';
import { NavContext, NavItems, NRSNavItem } from 'src/interfaces/navigation';
import { transformNRSNavItems } from 'src/utils/nav-items';

/**
 * This hook provides the footer nav items. The header uses a hook called useGetNav to fetch the nav items and write them
 * to local storage. Since this app is in a separate rendering context, we depend on the items to be read from local
 * storage here and updated when we receive a custom event from the HeaderApp.
 */
export const useFooterNavItems = (): NavContext => {
  const [navItems, setNavItems] = useState<NavItems>(() => {
    const storedItems = localStorage.getItem(FOOTER_NAV_ITEMS_LOCAL_STORAGE_KEY);

    return storedItems ? transformNRSNavItems(JSON.parse(storedItems) as NRSNavItem[]) : [];
  });

  // Subscribe to custom dom event since the items are managed by the header app which is outside this React root
  useEffect(() => {
    const handleItemsUpdated = () => {
      const storedItems = localStorage.getItem(FOOTER_NAV_ITEMS_LOCAL_STORAGE_KEY);
      if (storedItems) {
        setNavItems(transformNRSNavItems(JSON.parse(storedItems) as NRSNavItem[]));
      }
    };

    document.addEventListener(FOOTER_NAV_ITEMS_UPDATED_EVENT_NAME, handleItemsUpdated);

    return () => {
      document.removeEventListener(FOOTER_NAV_ITEMS_UPDATED_EVENT_NAME, handleItemsUpdated);
    };
  }, []);

  return {
    navItems,
  };
};
