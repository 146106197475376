import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { logger, logError } from 'src/logger';

interface RumConfig {
  sessionSampleRate: number;
  guestRoleArn: string;
  identityPoolId: string;
  endpoint: string;
  telemetries: string[];
  allowCookies: boolean;
  enableXRay: boolean;
  appId: string;
  appVersion: string;
  appRegion: string;
}

//  TODO: Add support for Cloudwatch Rum across preprod stages - https://app.asana.com/0/1206972973144714/1207623892232818/f
const rumConfigMap = new Map<string, RumConfig>([
  [
    'prod',
    {
      sessionSampleRate: 1,
      guestRoleArn: 'arn:aws:iam::851725357035:role/SSSAshTrimWebsite-Prod-CloudWatchRumMonitorprodusea-YXJMU8kwNOVp',
      identityPoolId: 'us-east-1:b8b6d8b3-15e3-43c9-ab8a-3a58121c972e',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['errors', 'performance', 'http'],
      allowCookies: true,
      enableXRay: false,
      appId: 'b78bed55-31d6-4dff-b99c-ab8785ed0167',
      appVersion: '1.0.0',
      appRegion: 'us-east-1',
    },
  ],
]);

export default (stage: string) => {
  const rumConfig = rumConfigMap.get(stage);
  if (rumConfig) {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: rumConfig.sessionSampleRate,
        guestRoleArn: rumConfig.guestRoleArn,
        identityPoolId: rumConfig.identityPoolId,
        endpoint: rumConfig.endpoint,
        telemetries: rumConfig.telemetries,
        allowCookies: rumConfig.allowCookies,
        enableXRay: rumConfig.enableXRay,
      };

      // Create an AWS Rum monitor for Trim
      // eslint-disable-next-line no-new
      new AwsRum(rumConfig.appId, rumConfig.appVersion, rumConfig.appRegion, config);
      logger.info(`Cloudwatch Rum created successfully with config: ${JSON.stringify(config)}`);
    } catch (error: any) {
      // Swallow errors thrown during CloudWatch RUM web client initialization
      logError('UseRum#rumConfig', 'Error while initializing CloudWatch Rum monitor', error);
    }
  }
};
