const botUserAgentPatterns = [
  // DAST Scanner user-agent string always contains this email
  // https://w.amazon.com/bin/view/Infosec/Proactive_Security/Dev/DASTPlatform/Documentation/CustomerSupport
  'prosecdev-dast-team@amazon.com',
];

/**
 * Matches the provided array of patterns against the user-agent string.
 * Return true on first match being found. Otherwise, returns false.
 */
export const isBotTraffic = () =>
  botUserAgentPatterns.some(
    (botUserAgentPattern) => window && window.navigator.userAgent.includes(botUserAgentPattern)
  );
