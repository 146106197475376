import { FunctionComponent } from 'react';

import logoSeaFoam from 'src/images/Amazon_Sustainability_Exchange_Logo_Sea_Foam.png';
import logoTeal from 'src/images/Amazon_Sustainability_Exchange_Logo_Teal.png';

import styles from './Logo.module.scss';

type StyleVariant = 'primary' | 'secondary';

interface LogoProps {
  altText: string;
  href: string;
  styleVariant?: StyleVariant;
}

export const Logo: FunctionComponent<LogoProps> = ({ altText, href, styleVariant = 'primary' }) => (
  <a className={styles.link} href={href}>
    <img alt={altText} className={styles.logo} src={styleVariant === 'primary' ? logoTeal : logoSeaFoam} />
  </a>
);
