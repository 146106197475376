var endpointMap = {
	en: {
		"roots.footer": "translation-en.json",
		"roots.header": "translation-en.json"
	},
	"en-US": {
		"roots.footer": "translation-en.json",
		"roots.header": "translation-en.json"
	},
	und: {
		"roots.footer": "translation.json",
		"roots.header": "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
