import { useMediaQuery } from 'react-responsive';

import theme from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

const useDeviceSize = () => {
  const isXSmallDevice = useMediaQuery({ query: `(max-width: ${theme.xSmallDevice})` });
  const isSmallDevice = useMediaQuery({ query: `(min-width: ${theme.smallDevice})` });
  const isMediumDevice = useMediaQuery({ query: `(min-width: ${theme.mediumDevice})` });
  const isLargeDevice = useMediaQuery({ query: `(min-width: ${theme.largeDevice})` });
  const isSmallDesktop = useMediaQuery({ query: `(min-width: ${theme.smallDesktop})` });
  const isMediumDesktop = useMediaQuery({ query: `(min-width: ${theme.mediumDesktop})` });
  const isLargeDesktop = useMediaQuery({ query: `(min-width: ${theme.largeDesktop})` });

  return {
    isXSmallDevice,
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isSmallDesktop,
    isMediumDesktop,
    isLargeDesktop,
  };
};

export default useDeviceSize;
