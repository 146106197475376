import { FunctionComponent } from 'react';
import arbManifest from 'translations/arbManifest';

import { MbmProvider } from '@amzn/react-arb-tools';

import { Footer } from 'src/components/footer/Footer';
import { getMbmOptions } from 'src/helpers';

const mbmOptions = getMbmOptions(arbManifest);

export const FooterApp: FunctionComponent = () => (
  <MbmProvider {...mbmOptions}>
    <Footer />
  </MbmProvider>
);
