import useSWRImmutable from 'swr/immutable';

import {
  HEADER_NAV_ITEMS_LOCAL_STORAGE_KEY,
  FOOTER_NAV_ITEMS_UPDATED_EVENT_NAME,
  FOOTER_NAV_ITEMS_LOCAL_STORAGE_KEY,
} from 'src/constants';
import { NavContext, NRSGetNavResponse, NRSNavItem } from 'src/interfaces/navigation';
import { logger } from 'src/logger';
import { isFooter, isHeader, transformNRSNavItems } from 'src/utils/nav-items';
import { swrFetcher } from 'src/utils/swr-helpers';

export const useGetNav = (): NavContext => {
  const { data, error, isLoading } = useSWRImmutable<NRSGetNavResponse>('/trim/json/nav', swrFetcher);
  const topLevelNav = data?.navigation?.subNav;

  if (error) {
    logger.error('Failed to load nav items from NRS.', error);
  }

  const EXPECTED_TOP_LEVEL_NAV_ITEMS = 2;
  let nrsHeaderItems = topLevelNav?.find(isHeader)?.subNav;
  const nrsFooterItems = topLevelNav?.find(isFooter)?.subNav;
  const isExpectedLength = topLevelNav?.length === EXPECTED_TOP_LEVEL_NAV_ITEMS;
  const isHeaderPresent = !!nrsHeaderItems;
  const isFooterPresent = !!nrsFooterItems;
  const isValidNavDoc = isExpectedLength && isHeaderPresent && isFooterPresent;

  // Persist to local storage - to be used as a fallback nav in case of an error or while loading
  // and to pass items to the footer
  if (topLevelNav) {
    if (isValidNavDoc) {
      // Persist the header and footer elements separately to optimize future transformations
      localStorage.setItem(HEADER_NAV_ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(nrsHeaderItems));
      localStorage.setItem(FOOTER_NAV_ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(nrsFooterItems));

      // The footer subscribes to this event and reads its nav items from local storage when updated
      document.dispatchEvent(new Event(FOOTER_NAV_ITEMS_UPDATED_EVENT_NAME));
    } else {
      logger.error('Unexpected nav document structure.', { isExpectedLength, isHeaderPresent, isFooterPresent });
    }
  }

  // Fallback to stored header items while loading, if there is an error, or if the nav doc is invalid
  if (error || isLoading || !isValidNavDoc) {
    const storedItems = localStorage.getItem(HEADER_NAV_ITEMS_LOCAL_STORAGE_KEY);
    if (storedItems) {
      nrsHeaderItems = JSON.parse(storedItems) as NRSNavItem[];
    }
  }

  if (error && !nrsHeaderItems) {
    logger.error('Failed to load stored nav items - compromised user experience.');
  }

  // Transform but fall back to an empty nav item list as a last resort if we can't find any items
  // TODO: Implement last-ditch fall-back mechanism: https://app.asana.com/0/1206972973144706/1207339021765356
  const transformedNavItems = transformNRSNavItems(nrsHeaderItems ?? []);

  return {
    navItems: transformedNavItems,
  };
};
