import classNames from 'classnames';
import { FunctionComponent, useState, useEffect } from 'react';

import { useWeblab } from '@amzn/sustainability-central-shared-react';

import { MenuItem } from 'src/components/common/menu-item/MenuItem';
import { useGetNav } from 'src/components/header/hooks/use-get-nav';
import { NAV_ITEM_TYPE, NAV_PLACEMENT } from 'src/enums/navigation';
import windMillImage from 'src/images/windmills.png';
import { NavItem, NavItems } from 'src/interfaces/navigation';
import { isLocalePicker, isSearch } from 'src/utils/nav-items';

import styles from './Header.module.scss';

const INITIAL_SUB_NAV_PARENT: NavItem | null = null;

const activateDenyList = [
  NAV_ITEM_TYPE.LOGO,
  NAV_ITEM_TYPE.SUST_SITE_LINK,
  NAV_ITEM_TYPE.HAMBURGER,
  NAV_ITEM_TYPE.CLOSE,
  NAV_ITEM_TYPE.LOCALE_PICKER,
];
const isActivateEligible = (item: NavItem): boolean => !activateDenyList.includes(item.itemType);

const getInitialActiveItem = (navItems: NavItems): NavItem | null => {
  let active: NavItem | null = null;
  navItems.forEach((item) => {
    if (isActivateEligible(item) && item.url === window.location.pathname) {
      active = item;
    }
  });

  return active;
};

const deviceNavDenyList = [NAV_ITEM_TYPE.SEARCH, NAV_ITEM_TYPE.LOGO, NAV_ITEM_TYPE.HAMBURGER, NAV_ITEM_TYPE.CLOSE];
const isDeviceNavEligible = (item: NavItem): boolean => !deviceNavDenyList.includes(item.itemType);

export const Header: FunctionComponent = () => {
  const isLocalePickerEnabled = useWeblab('ASX_LOCALE_P0_LAUNCH_1047472') === 'T1';

  const { navItems } = useGetNav();
  const [subNavParent, setSubNavParent] = useState<NavItem | null>(INITIAL_SUB_NAV_PARENT);
  const isSubNavExpanded = !!subNavParent;
  const [activeItem, setActiveItem] = useState<NavItem | null>(getInitialActiveItem(navItems));
  const [isDeviceMenuExpanded, setIsDeviceMenuExpanded] = useState<boolean>(false);
  const isDeviceNav = isDeviceMenuExpanded && !isSubNavExpanded;
  const isDeviceSubNav = isDeviceMenuExpanded && isSubNavExpanded;
  const sustSiteLinkItem = navItems.find((item) => item.itemType === NAV_ITEM_TYPE.SUST_SITE_LINK);
  const injectAdditionalDeviceSubNavItems = (parentItem: NavItem): NavItems => {
    const { children: items } = parentItem;
    const itemsWithSiteLink = sustSiteLinkItem ? [...items, sustSiteLinkItem] : items;

    return [parentItem, ...itemsWithSiteLink];
  };

  const [isHidden, setIsHidden] = useState(false);
  const hideHeaderYThreshold = 420;

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (isSubNavExpanded) {
        // If subNav menus are open, header should not be hidden
        setIsHidden(false);
      } else if (currentScrollY > lastScrollY && currentScrollY > hideHeaderYThreshold) {
        // Scrolling down and passed a threshold, hide header
        setIsHidden(true);
      } else if (currentScrollY < lastScrollY) {
        // Scrolling up, show header
        setIsHidden(false);
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSubNavExpanded]);

  useEffect(() => {
    const handleHideHeaderContainer = () => {
      const headerContainer = document.getElementById('sc-navbar-container');
      if (headerContainer) {
        headerContainer.classList.remove('show', 'hide');
        headerContainer.classList.add(isHidden ? 'hide' : 'show');
        headerContainer.setAttribute('aria-hidden', String(isHidden));
      }
    };

    handleHideHeaderContainer();
  }, [isHidden]);

  useEffect(() => {
    const rootElement = document.documentElement;
    if (isDeviceMenuExpanded) {
      rootElement.classList.add('no-scroll');
    } else {
      rootElement.classList.remove('no-scroll');
    }
  }, [isDeviceMenuExpanded]);

  const handleDeviceMenuExpand = () => {
    setIsDeviceMenuExpanded(true);
  };

  const handleDeviceMenuCollapse = () => {
    setIsDeviceMenuExpanded(false);
    setSubNavParent(INITIAL_SUB_NAV_PARENT);
  };

  const handleMenuExpand = (expandedItem: NavItem) => {
    setActiveItem(expandedItem);
    setSubNavParent(expandedItem);
  };

  const handleMenuCollapse = () => {
    setSubNavParent(INITIAL_SUB_NAV_PARENT);
  };

  const handleMouseLeaveHeader = () => {
    if (isDeviceMenuExpanded) {
      return;
    }

    setActiveItem(getInitialActiveItem(navItems));

    if (isSubNavExpanded) {
      handleMenuCollapse();
    }
  };

  const handleMouseEnterSubNav = () => {
    setActiveItem(subNavParent);
  };

  const handleItemActivate = (navItem: NavItem) => {
    setActiveItem(navItem);
  };

  // Normally we'd fetch strings from Panther and wait for isBundleLoading to render a nav, but the translations for
  // the nav items come from NRS so we can use those and get the nav on the page sooner and decrease rendering lag
  return (
    <header
      className={classNames(styles.header, { [styles.expanded]: isSubNavExpanded || isDeviceMenuExpanded })}
      onMouseLeave={handleMouseLeaveHeader}
    >
      <nav aria-label="primary" className={styles.navigation}>
        {/* Desktop Nav menu and device top nav */}
        <ul className={styles.listContainer}>
          {navItems
            .filter((item) => !isLocalePicker(item) || isLocalePickerEnabled)
            .map((item) => (
              <MenuItem
                isAlignedRight={isSearch(item) || isLocalePicker(item)}
                isDeviceMenuExpanded={isDeviceMenuExpanded}
                isItemActive={item.id === activeItem?.id}
                isItemExpanded={item.id === subNavParent?.id}
                isNavExpanded={isSubNavExpanded}
                key={item.id}
                navItem={item}
                onDeviceMenuCollapse={handleDeviceMenuCollapse}
                onDeviceMenuExpand={handleDeviceMenuExpand}
                onItemActivate={isActivateEligible(item) ? handleItemActivate : undefined}
                onMenuExpand={handleMenuExpand}
                onMenuCollapse={handleMenuCollapse}
                placement={NAV_PLACEMENT.HEADER}
              />
            ))}
        </ul>

        {/* Desktop SubNav menu */}
        {isSubNavExpanded && !isDeviceMenuExpanded && (
          <div data-testid="subnav-container" className={styles.subNavContainer} onMouseEnter={handleMouseEnterSubNav}>
            <ul className={styles.subNavListContainer}>
              {subNavParent.children.map((item) => (
                <MenuItem
                  isDeviceMenuExpanded={isDeviceMenuExpanded}
                  isNavExpanded={isSubNavExpanded}
                  key={item.id}
                  navItem={item}
                  placement={NAV_PLACEMENT.SUB_NAV}
                />
              ))}
            </ul>
            {/* TODO: Implement dynamic image when hover over sub nav item: https://app.asana.com/0/1206972973144706/1207403964742386 */}
            <img className={styles.subNavImage} src={windMillImage} alt="placeholder" />
          </div>
        )}

        {/* Device Nav and SubNav menu */}
        {isDeviceMenuExpanded && (
          <ul
            className={classNames(styles.deviceListContainer, {
              [styles.deviceNavListContainer]: isDeviceNav,
              [styles.deviceSubNavListContainer]: isDeviceSubNav,
            })}
          >
            {/* Device Nav menu items */}
            {isDeviceNav &&
              navItems
                .filter(isDeviceNavEligible)
                .map((item) => (
                  <MenuItem
                    isDeviceMenuExpanded={isDeviceMenuExpanded}
                    key={item.id}
                    navItem={item}
                    onMenuExpand={handleMenuExpand}
                    placement={NAV_PLACEMENT.DEVICE_NAV}
                  />
                ))}

            {/* Device SubNav menu items */}
            {isDeviceSubNav &&
              injectAdditionalDeviceSubNavItems(subNavParent).map((item) => (
                <MenuItem
                  isDeviceMenuExpanded={isDeviceMenuExpanded}
                  isItemExpanded={item.id === subNavParent?.id}
                  key={item.id}
                  navItem={item}
                  onMenuCollapse={handleMenuCollapse}
                  placement={NAV_PLACEMENT.DEVICE_SUB_NAV}
                />
              ))}
          </ul>
        )}
      </nav>
    </header>
  );
};
