import classNames from 'classnames';
import { FunctionComponent } from 'react';

import HomeIcon from 'src/icons/home_outlined.svg';

import styles from './ButtonLink.module.scss';

export type ButtonLinkVariant = 'primary' | 'secondary' | 'tertiary';
interface ButtonLinkProps {
  href: string;
  text: string;
  variant?: ButtonLinkVariant;
}

export const ButtonLink: FunctionComponent<ButtonLinkProps> = ({ href, text, variant = 'primary' }) => {
  const isSecondary = variant === 'secondary';
  const isTertiary = variant === 'tertiary';

  return (
    <a
      className={classNames(styles.buttonLink, {
        [styles.secondaryButtonLink]: isSecondary,
        [styles.tertiaryButtonLink]: isTertiary,
      })}
      href={href}
    >
      <span className={classNames(styles.buttonText, { [styles.buttonTextTertiary]: isTertiary })}>{text}</span>
      <div
        className={classNames(styles.iconContainer, {
          [styles.secondaryIconContainer]: isSecondary,
          [styles.tertiaryIconContainer]: isTertiary,
        })}
      >
        <HomeIcon
          className={classNames(styles.homeIcon, {
            [styles.homeIconSecondary]: isSecondary,
            [styles.homeIconTertiary]: isTertiary,
          })}
        />
      </div>
    </a>
  );
};
