import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';

import ChevronRightIcon from 'src/icons/chevron-right.svg';
import ExternalLinkIcon from 'src/icons/chevron-top-right.svg';
import utilStyles from 'src/styles/_utility.scss';

import styles from './Link.module.scss';

export type LinkStyleVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

interface LinkProps {
  href: string;
  styleVariant: LinkStyleVariant;
  text: string;
  variant?: 'external';
}

export const Link: FunctionComponent<LinkProps> = ({ href, styleVariant, text, variant = 'internal' }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const isSecondary = styleVariant === 'secondary';
  const isTertiary = styleVariant === 'tertiary';
  const isQuaternary = styleVariant === 'quaternary';

  const handleMouseEnter = () => {
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    setIsActive(false);
  };

  return (
    <a
      className={classNames(styles.link, {
        [styles.secondary]: isSecondary,
        [styles.tertiary]: isTertiary,
        [styles.quaternary]: isQuaternary,
      })}
      href={href}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      target={variant === 'external' ? '_blank' : '_self'}
      rel="noreferrer"
    >
      <span className={utilStyles.noWrap}>{text}</span>
      {isTertiary && isActive && <ChevronRightIcon className={styles.activeLinkIcon} />}
      {variant === 'external' && (
        <span className={utilStyles.noWrap}>
          &nbsp;
          <ExternalLinkIcon role="img" aria-label="Opens in a new tab" className={styles.externalLinkIcon} />
        </span>
      )}
    </a>
  );
};
