import { FunctionComponent } from 'react';

import styles from './SubNavTitle.module.scss';

interface SubNavTitleProps {
  text: string;
}

export const SubNavTitle: FunctionComponent<SubNavTitleProps> = ({ text }) => (
  <div className={styles.subNavTitle}>{text}</div>
);
