import classNames from 'classnames';
import { FunctionComponent } from 'react';

import ChevronDownIcon from 'src/icons/chevron-down.svg';
import ChevronLeftIcon from 'src/icons/chevron-left.svg';
import ChevronRightIcon from 'src/icons/chevron-right.svg';
import ChevronUpIcon from 'src/icons/chevron-up.svg';
import utilStyles from 'src/styles/_utility.scss';

import styles from './Parent.module.scss';

export type ParentStyleVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';
interface ParentProps {
  text: string;
  isExpanded: boolean;
  styleVariant?: ParentStyleVariant;
  onClick: () => void;
}

export const Parent: FunctionComponent<ParentProps> = ({ isExpanded, onClick, text, styleVariant = 'primary' }) => {
  const isSecondary = styleVariant === 'secondary';
  const isTertiary = styleVariant === 'tertiary';
  const isQuaternary = styleVariant === 'quaternary';

  const buttonStyles = classNames(styles.parent, {
    [styles.buttonSecondary]: isSecondary,
    [styles.buttonTertiary]: isTertiary,
    [styles.buttonQuaternary]: isQuaternary,
  });
  const iconStyles = classNames(styles.parentIcon, {
    [styles.iconSecondary]: isSecondary,
    [styles.iconTertiary]: isTertiary,
    [styles.iconQuaternary]: isQuaternary,
  });

  return (
    <button className={buttonStyles} onClick={onClick} type="button">
      {isQuaternary && <ChevronLeftIcon className={iconStyles} />}
      <span className={utilStyles.noWrap}>{text}</span>
      {!(isTertiary || isQuaternary) && (
        <span className={utilStyles.noWrap}>
          &nbsp;
          {isExpanded ? <ChevronUpIcon className={iconStyles} /> : <ChevronDownIcon className={iconStyles} />}
        </span>
      )}
      {isTertiary && <ChevronRightIcon className={iconStyles} />}
    </button>
  );
};
