import { FunctionComponent } from 'react';

import CloseIcon from 'src/icons/close.svg';

import styles from './CloseButton.module.scss';

interface CloseButtonProps {
  ariaLabel: string;
  onClick: () => void;
}

export const CloseButton: FunctionComponent<CloseButtonProps> = ({ ariaLabel, onClick }) => (
  <button className={styles.closeButton} type="button" aria-label={ariaLabel} onClick={onClick}>
    <CloseIcon className={styles.closeIcon} role="img" />
  </button>
);
