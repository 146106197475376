import translationen from './translation-en.json';
import translation from './translation.json';

// webpack transforms these JSON imports to resource URLs, so we cast our export accordingly here.
const i18nEndPointMap: Record<string, string> = {
  'translation-en.json': translationen as unknown as string,
  'translation.json': translation as unknown as string
}

export default i18nEndPointMap;
